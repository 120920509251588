.flex-c-a {
  justify-content: center;
  align-items: center;
}

.calendar-container {
  margin-top: 0.5rem;
  border: 0.5px solid #e5e5e5;
}
.header-cell {
  color: #cae6fe;
  text-align: center;
  border: 0.5px solid #6ebbef;
  line-height: 30px;
}

.header-row {
  display: flex;
  background-color: #6ebbef;
}

.header-title {
  cursor: pointer;
  padding: 4px 16px;
  color: #fff;
  border-radius: 8px;
}

.header-title:hover {
  background-color: #e9e9e9;
}

.navigation-text-container {
  display: flex;
  justify-content: center;
}
.navigation-text {
  display: inline-block;
  padding: 2px 5px;
  color: #1976d2;
  font-size: 13px;
  margin: 0 12px;
}

.navigation-arrow {
  padding: 0 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.cal-row {
  display: flex;
  position: relative;
  min-height: 80px;
}

.cal-cell {
  width: 25%;
  height: 80px;
  border: 0.3px solid #e5e5e5;
}

.event-list-container {
  max-height: 600px;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 0.5px solid #e5e5e5;
}
.event-list-container-wrapper {
  position: relative;
}

.no-scroll {
  overflow: hidden;
}

.event {
  position: absolute;
  height: 70px;
  background: #e8fcf8;
  top: 10px;
  border-radius: 5px;
  border-width: 1px 1px 1px 10px;
  border-style: solid;
}
.event-progress {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
}
.event-title-div:hover {
  box-shadow: 0 0 16px 5px #dddddd;
}
.event-title-div {
  padding: 0px 16px;
  border-radius: 5px;
  display: flex;
  top: 10px;
  line-height: 24px;
  position: absolute;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
}

.event-details-container {
  padding: 12px 16px;
}

.event-title {
  margin-left: 4px;
}

.event-details {
  padding: 8px 0px;
  border-width: 1px 0px 1px 0px;
  border-style: solid;
}

.event-detail-title {
  color: #c1c1c1;
}

.event-detail-value {
  color: #404040;
}

.event-actions-container {
  padding-top: 8px;
}

.event-action-btn {
  color: #404040;
  background-color: #fff;
  border: none;
}

.event-action-title {
  margin-left: 4px;
}

.toggle-container {
  position: absolute;
  right: 0;
  top: -0.3rem;
}

.toggle-container > .btn-group > .btn-primary {
  background-color: #ffffff !important;
  border-color: #44677b !important;
  color: #44677b !important;
}
.toggle-container > .btn-group > .btn-primary:focus {
  box-shadow: none !important;
}
.toggle-container > .btn-group > .btn-primary.active {
  color: white !important;
  border-color: #44677b !important;
  background-color: #44677b !important;
}
.toggle-container > .btn-group > .btn-primary.active:focus {
  box-shadow: none !important;
}
.toggle-container > .btn-group > .btn-primary:active:focus {
  box-shadow: none !important;
}
.toggle-container > .btn-group > .btn-primary:active {
  color: white !important;
  border-color: #44677b !important;
  background-color: #44677b !important;
}

.loading {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.701) 50% 50% no-repeat;
}

.loading-text {
  position: absolute;
  z-index: 1001;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: #1976d2;
}

.calendar-events-loading-wrapper {
  position: relative;
}
